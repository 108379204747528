<template>
  <div class="page page--terms terms">
    <div class="page__content">
      <h1>Algemene voorwaarden</h1>
      <h2>1.Algemeen</h2>
      <p>Onderhavige algemene voorwaarden zijn van toepassing op alle offertes, bestellingen en diensten van de BV V-Tax Lybaert, met zetel te 9000 Gent, Vliegtuiglaan 5 en met ondernemingsnummer 0729.541.453 (hierna: V-Tax), behoudens uitdrukkelijk andersluidend beding en met uitsluiting van de voorwaarden van de medecontractant. V-Tax legt zich toe op het bezoldigd personenvervoer.</p>
      <p>Als medecontractant wordt eenieder beschouwd die een contractuele band heeft met V-Tax (hierna: Klant). V-Tax voert zowel diensten uit in opdracht van ondernemingen (hierna: Onderneming(en)) als in opdracht van natuurlijke personen die handelen voor doeleinden die geen verband houden met enige beroepsactiviteit of commerciële activiteit (hierna: Consument(en)).</p>
      <p>Aangetekende brieven dienen steeds gericht te worden aan V-Tax op bovenvernoemd adres. Andere schriftelijke communicatie dient gericht te worden aan het mailadres van V-Tax (info@v-tax.be).</p>
      <h2>2.Bestellingen en opdrachten</h2>
      <p>Ritten kunnen besteld worden per telefoon, per mailbericht, via een online bestelproces (bestelling op afstand), of door middel van de hand op te steken. De prijzen die desgevallend gehanteerd worden, werden door V-Tax zelf vastgelegd. Deze prijzen worden steeds geafficheerd in het voertuig. Bij gebruik van een taxi gaat de Klant uitdrukkelijk akkoord met deze tarieven.</p>
      <p>Een rit kan tevens besteld worden op een taxistandplaats, zoals door de overheid vastgelegd. De tarieven van dergelijke rit, vertrekkende vanaf een taxistandplaats, worden vastgelegd door de gemeente/stad van de plaats waar deze rit werd aangevat.</p>
      <p>Prijzen zijn steeds afhankelijk van externe factoren zodat de prijs van een bepaalde rit op dat bepaald moment niet toegepast kan worden op een andere rit of dezelfde rit op een ander moment. Indien een korting wordt verleend dan wordt deze korting geacht slechts eenmalig te zijn verleend, tenzij anders vermeld. Alle péage of tol is altijd extra te betalen door de Klant, tenzij anders vermeld bij de offerte, reservatie of vervoersdocument.</p>
      <p>Bij een bestelling op afstand zal de centrale van V-Tax de wachttijd, zijnde de tijd totdat de taxi de passagier kan ophalen, meedelen. Deze wachttijd is indicatief en louter een inschatting. De taxi kan dus steeds wat later zijn, maar ook wat vroeger. De Klant dient hier rekening mee te houden.</p>
      <h2>3.Verantwoordelijkheid Klant</h2>
      <p>De Klant is verantwoordelijk voor de correcte ingave en mededeling van alle gegevens zoals:</p>
      <ul>
        <li>het ophaaladres,</li>
        <li>het adres waar de passagier moet worden afgezet,</li>
        <li>bepaale specifieke wensen van de passagier,</li>
        <li>het aantal stukken bagage,</li>
        <li>het aantal passagiers,</li>
        <li>de reservatie van een kinderstoel,</li>
        <li>de aanwezigheid van rolstoelen of babybenodigdheden,</li>
        <li>de aanwezigheid van dieren,</li>
        <li>ieder ander gegeven dat redelijkerwijze relevant kan zijn voor de reservatie en/of de uitvoering van de rit.</li>
      </ul>
      <p>De Klant is tevens verantwoordelijk voor de vlotte bereikbaarheid van de plaats van ophaling. Enige vertraging hierdoor valt ten laste van de Klant.</p>
      <p>Indien V-Tax schade zou lijden doordat de plaats van ophaling niet vlot bereikbaar bleek, zal de Klant V-Tax voor de schade volledig vrijwaren.</p>
      <p>Wanneer bepaalde wensen niet werden aangegeven bij de bestelling heeft V-Tax het recht deze bijkomende wensen te weigeren uit te voeren of een bijkomende vergoeding aan te rekenen overeenkomstig de courante eenheidsprijzen. Dit is bijvoorbeeld het geval bij extra bagage, een extra passagier, etc.</p>
      <p>Er wordt van de Klant verwacht dat hij V-Tax zo snel mogelijk contacteert wanneer blijkt dat de chauffeur niet aanwezig is op de afgesproken plaats op het afgesproken tijdstip.</p>
      <p>Om de ritten zo vlot mogelijk te laten verlopen, wordt er verwacht van de passagiers dat ze klaarstaan op het afgesproken uur. Indien dit niet het geval is en de chauffeur moet langer wachten dan 15 minuten wordt er een wachttijd aangerekend volgens de courante eenheidsprijzen. De Klant maakt zich sterk dat de passagiers hieraan voldoen en zal V-Tax zo-nodig vrijwaren.</p>
      <h2>3.Annulering/stopzetting</h2>
      <p>V-Tax heeft het recht de bestelling te annuleren/stop te zetten, met onmiddellijke ingang en zonder ingebrekestelling, noch schadevergoeding bij staking van betaling of (aanvraag van) het faillissement door de Klant of bij vereffening of stopzetting van de activiteiten van de Klant of indien de Klant, ondanks schriftelijke ingebrekestelling waarbij een termijn van 7 kalenderdagen in acht wordt genomen, in gebreke blijft de verplichting na te komen. V-Tax heeft eveneens steeds het recht de bestelling te annuleren in geval van overmacht.</p>
      <p>De Klant kan de bestelling eveneens annuleren mits betaling van een vergoeding die gelijk is aan de kosten die V-Tax reeds heeft gemaakt met een minimum van 40% van het bedrag dat initieel betaald diende te worden.</p>
      <p>V-Tax mag, zonder enige schadevergoeding voor de Klant/passagiers, de rit geheel of gedeeltelijk annuleren indien er zich voor of tijdens de uitvoering van de rit overmachtssituaties en/of buitengewone omstandigheden voordoen, zijnde omstandigheden die V-Tax bij bevestiging van de rit niet kende of kon kennen en die, indien V-Tax ze wel had gekend, een geldige reden zouden zijn geweest om de rit niet uit te voeren. Buitengewone omstandigheden zijn onder meer vervoer van illegale personen of goederen, onvoorzienbare routewijzigingen, etc.</p>
      <p>Wanneer geen annulering werd gegeven, 12 uur voor aanvang, of een no-show wordt het volledige bedrag aangerekend.</p>
      <p>Dit artikel en zijn bepalingen zijn van toepassing behoudens enige tegenstrijdige wettelijke dwingende bepaling.</p>
      <h2>4.Uitvoering</h2>
      <p>V-Tax streeft er naar de passagiers op te halen op het daartoe vooraf meegedeelde tijdstip, in zoverre er een tijdstip werd meegedeeld. Dit is evenwel afhankelijk van externe factoren zoals onder meer doch niet beperkt tot extreem weer, sneeuw, ijzel, files, verkeersongevallen, etc. </p>
      <p>V-Tax zal naar best vermogen de passagier vervoeren naar het opgegeven adres binnen een zo kort mogelijke tijdspanne. Dit laatste betreft een middelenverbintenis. </p>
      <p>In het kader van de verkeersveiligheid kan V-Tax in uitzonderlijke omstandigheden er voor opteren de opdracht niet uit te voeren.</p>
      <p>V-Tax heeft steeds het recht een passagier, al dan niet op voorhand, te weigeren wanneer deze de verkeersveiligheid in het gedrang zou brengen en/of wanneer de passagier in een gesteldheid verkeert die een ordentelijke rit in de weg zou kunnen staan.</p>
      <p>V-Tax kan niet aansprakelijk gesteld worden voor vergoeding van enige schade die het gevolg is van laattijdigheid door externe factoren zoals onder meer extreem weer, sneeuw, ijzel, files, verkeersongevallen.</p>
      <p>Wanneer de Klant in gebreke blijft een bestelling te betalen, is V-Tax gerechtigd al haar prestaties voor die Klant op te schorten totdat die bestelling volledig werd voldaan.</p>
      <p>De Consument heeft evenzeer de mogelijkheid zijn prestaties op te schorten wanneer V-Tax in gebreke blijft haar verbintenissen te voldoen.</p>
      <h2>5.Aansprakelijkheid V-Tax</h2>
      <p>De aansprakelijkheid van V-Tax kan geen aanleiding geven tot enige schadevergoeding, behalve in geval van opzettelijke of zware fout van V-Tax of haar aangestelden.</p>
      <p>Ten aanzien van passagiers die niet een Klant zijn, kan V-Tax niet rechtstreeks door deze aangesproken worden, behoudens enige dwingende wettelijke bepaling.</p>
      <p>De aansprakelijkheid van V-Tax wordt steeds beperkt tot maximaal de waarde zoals vermeld op de bestelling met een maximum van € 1.000,00. V-Tax kan nooit aansprakelijk gesteld worden voor indirecte schade, zoals maar niet beperkt tot, gederfde inkomsten, winsten, verlies van een klant, of eender welke gevolgschade.</p>
      <p>V-Tax is niet aansprakelijk wanneer de passagier zich niet op het aangewezen tijdstip en/of niet op het meegedeeld ophaaladres aanbiedt aan de chauffeur van V-Tax. Enige vertraging of daaruitvloeiende annulering valt enkel ten laste van de Klant.</p>
      <h2>6.Overmacht</h2>
      <p>In het geval van overmacht is elke partij bevrijd en niet gehouden tot nakoming van enige verbintenis jegens de wederpartij. Onder overmacht wordt verstaan de situatie waarin de uitvoering van de overeenkomst door één der partijen geheel of gedeeltelijk, al dan niet tijdelijk verhinderd wordt door omstandigheden buiten de wil van die partij. Zonder limitatief te willen zijn, wordt als geval van overmacht beschouwd: technische storingen aan het vervoermiddel die niet aan enige fout of nalatigheid van V-Tax te wijten is, diefstal van het vervoermiddel, extreme weersomstandigheden die afbreuk doen aan de verkeersveiligheid, stakingen, pandemie, etc.</p>
      <h2>7.Gedrag passagiers</h2>
      <p>Iedere passagier dient de verkeersregels in acht te nemen. Dit impliceert tevens dat dieren, uitgezonderd begeleidingshonden, veilig vervoerd moeten kunnen worden door middel van een bench en/of dierentas.</p>
      <p>Iedere passagier dient zich te onthouden van beschadigingen en verontreiniging van de wagen, het overmatige gebruik van alcohol, het transporteren en/of gebruiken van verboden verdovende middelen, roken, de chauffeur te verhinderen van zijn taken uit te voeren, etc.</p>
      <p>Bij enige inbreuk op dit artikel is V-Tax gerechtigd op een schadevergoeding forfaitair begroot op een bedrag van 50% van de prijs van de bestelling met een minimum van € 150,00 onverminderd het recht van V-Tax om een hogere vergoeding te vorderen wanneer de werkelijke schade groter zou zijn.</p>
      <p>De Klant maakt zich sterk deze schadevergoeding te betalen, alsook V-Tax te vrijwaren voor eventuele boetes die aan haar worden opgelegd.</p>
      <p>Er wordt verwacht dat de Klant de passagiers in kennis stelt van de algemene voorwaarden van V-Tax, waaronder uiteraard begrepen de specifieke regels ten opzichte van de passagiers, die toch wel vanzelfsprekend zijn.</p>
      <h2>8.Betaling</h2>
      <p>Consumenten dienen de bestelling steeds cash of per betaalkaart te betalen, behoudens andersluidende uitdrukkelijke afspraak. Behoudens andersluidende afspraak dient de Consument de betaling uit te voeren voorafgaand aan de rit of onmiddellijk nadat de rit werd uitgevoerd.</p>
      <p>Ondernemingen kunnen opteren om de bestelling te betalen zoals dat het geval is bij Consumenten, maar hebben eveneens de mogelijkheid de rit te betalen via overschrijving nadat zij een factuur heeft mogen ontvangen, behoudens andersluidende afspraak. </p>
      <p>De facturen zijn desgevallend betaalbaar ten laatste op de vervaldag op het adres van V-Tax, behoudens andersluidende afspraken. Wanneer een factuur niet tijdig betaald wordt, is van rechtswege en zonder enige ingebrekestelling, een verwijlinterest verschuldigd van 10%, alsook een forfaitaire schadevergoeding van 15% met een minimum van € 125,00. Dit beding sluit geenszins aanvullende schadevergoedingen uit. Laattijdige betalingen worden eerst aangerekend op de verhogingen en dan pas op de hoofdsom.</p>
      <p>Protest tegen een factuur dient binnen de 8 dagen na ontvangst ervan per aangetekend schrijven te gebeuren op het adres van de maatschappelijke zetel van V-Tax.</p>
      <p>V-Tax wijst op de strafrechtelijke sancties die het gevolg kunnen zijn van een gebrekkige betaling. Desgevallend houdt V-Tax haar het recht voor klacht in te dienen bij de bevoegde diensten.</p>
      <h2>9.Klachten</h2>
      <p>Klachten omtrent de dienstverlening van V-Tax of omtrent het gedrag van een chauffeur van V-Tax kunnen steeds gericht worden aan V-Tax via het mailadres info@v-tax.be.</p>
      <p>Klachten kunnen evenzeer gemeld worden aan Mobiliteitsbedrijf Stad Gent.</p>
      <h2>10.Geschillen</h2>
      <p>De ongeldigheid van een (gedeelte van een) bepaling heeft geen invloed op de geldigheid van de overige ( gedeeltes van de) bepalingen. </p>
      <p>De rechtbanken van het arrondissement waar de maatschappelijke zetel van V-Tax zich bevindt, zijn exclusief bevoegd om kennis te nemen van ieder geschil, behoudens enige dwingende wettelijke bepaling.</p>
      <p>Het Belgisch recht is van toepassing.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms"
}
</script>